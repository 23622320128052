import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import styles from './Header.module.scss';
import { ShieldCheck } from 'react-bootstrap-icons';
import { useIsMobile } from './useIsMobile';

/**
 * Header.
 *
 * @returns React component.
 */
export const Header = (): ReactElement => {
  const isMobile = useIsMobile();
  return (
    <Navbar
      expand="lg"
      className={styles.header}
      variant="grey"
    >
      <Container className="justify-content-center  ">
        <Navbar.Brand className={styles.brand}>
          {/* <ShieldCheck color="#c3c3c3" size={32} /> */}
          {' '}
          {isMobile ? (
            <div>
              <ShieldCheck size={26} />{' '}
          Искуственный интеллект
            </div>
          ) : (
            <span>
              <ShieldCheck size={26} />{' '}
          Искуственный интеллект
          </span>
          )}
          <div className="d-flex justify-content-center">
          <img src="/images/logo_cryptopro.svg" alt="" width={160} />
          </div>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};
